import tpsApiInstance from "./tpsApiBase";
import {
  IReportGetListTotalNumRequestParams,
  IReportGetListRequestParams,
  IUpdateCalStatus
} from './models/IReport';

const REPORT_PATH = "/listTx";

export const apiReportGetListTotalNum = async (
  pData?: IReportGetListTotalNumRequestParams): Promise<number> => {
  let query: string = "?count=total&";
  query += convertQueryValue(pData);
  console.log("query:", query)
  const res = await tpsApiInstance.get(`${REPORT_PATH}${query}`);
  console.log("total:", res?.data[0]?.total);
  return res?.data[0]?.total;
};

export const apiUpdateCalStatus = async (
  pData?: IUpdateCalStatus): Promise<number> => {
  let query: string = `?txn_date=${pData?.txn_date}&txn_time=${pData?.txn_time}&reader_id=${pData?.reader_id}&card_id=${pData?.card_id}&cal_status=${pData?.cal_status}`;

  console.log("query:", query)
  const res = await tpsApiInstance.get(`/txn/update_cal_status${query}`);
  console.log("total:", res?.data[0]?.total);
  return res?.data[0]?.total;
};

/* report="1":交易明細表
   report="2":愛心卡點數請款明細表 */
export const apiReportGetList = async (
  pData?: IReportGetListRequestParams): Promise<[] | Blob> => {
  let query: string = "?";
  query += convertQueryValue(pData);

  if (pData && pData.output) {
    query += `&output=${pData.output}`;
    console.log("query:", query);
    const res = await tpsApiInstance.get(`${REPORT_PATH}${query}`, { responseType: 'blob' });
    return res?.data;
  }
  else {
    console.log("query:", query);
    const res = await tpsApiInstance.get(`${REPORT_PATH}${query}`);
    return res?.data;
  }
};

function convertQueryValue(pData): string {
  let query = "";
  if (pData && pData.report) query += `report=${pData.report}`;
  if (pData && pData.limit && pData.page) query += `&limit=${pData.limit}&offset=${(pData.page - 1) * pData.limit}`;
  if (pData && pData.card_id) query += `&card_id=${pData.card_id}`;
  if (pData && pData.cardtype) query += `&cardtype=${pData.cardtype}`;
  if (pData && pData.off) query += `&off=${pData.off}`;
  if (pData && pData.plate) query += `&plate=${pData.plate}`;
  if (pData && pData.vid) query += `&vid=${pData.vid}`;
  if (pData && pData.rid) query += `&rid=${pData.rid}`;
  if (pData && pData.rsn) query += `&rsn=${pData.rsn}`;
  if (pData && pData.ci) query += `&ci=${pData.ci}`;
  if (pData && pData.an) query += `&an=${pData.an}`;
  if (pData && pData.brand_id) query += `&brand_id=${pData.brand_id}`;
  if (pData && pData.txn_class) query += `&txn_class=${pData.txn_class}`;
  if (pData && pData.txn_type) query += `&txn_type=${pData.txn_type}`;
  if (pData && pData.cal_status) query += `&cal_status=${pData.cal_status}`;
  if (pData && pData.txn_fail) query += `&txn_fail=${pData.txn_fail}`;
  if (pData && pData.abnormal_status_id) query += `&abnormal_status_id=${pData.abnormal_status_id}`;
  if (pData && pData.startdate_tx && pData.enddate_tx) query += `&startdate_tx=${pData.startdate_tx}&enddate_tx=${pData.enddate_tx}`;
  if (pData && pData.startdate_dl && pData.enddate_dl) query += `&startdate_dl=${pData.startdate_dl}&enddate_dl=${pData.enddate_dl}`;
  if (pData && pData.card_area) query += `&card_area=${pData.card_area}`;

  return query;
}